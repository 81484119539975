import React, { createRef, useEffect } from "react";

import lottie from "lottie-web";

const AnimationSvg = ({ width, height, animation }: { width: number; height: number; animation: any }) => {
  const animationContainer = createRef<any>();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current as any,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    });

    return () => anim.destroy();
  }, []);
  // eslint-disable-next-line react/jsx-filename-extension
  return <div ref={animationContainer} style={{ width: `${width}px`, height: `${height}px` }} />;
};

export default AnimationSvg;
