import styled from "styled-components";
import Link from "next/link";
import { NextSeo } from "next-seo";
import { Button, Heading, Text, LogoSkeletonIcon } from "@pancakeswap/uikit";
import { useTranslation } from "@pancakeswap/localization";
import AnimationLoading from "../AnimationLoading/AnimationLoading";

const StyledButton = styled.button`
  height: 40px;
  border-radius: 12px;
  box-shadow: none;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  background-color: #2e81ff;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
`;

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`;

const NotFound = ({ statusCode = 404 }: { statusCode?: number }) => {
  const { t } = useTranslation();

  return (
    <>
      <NextSeo title="404" />
      <StyledNotFound>
        <AnimationLoading width={80} height={80} />
        <Heading scale="xxl" color="#fff" fontWeight="700" fontSize="14px" lineHeight="140%" mt="60px">
          {statusCode}
        </Heading>
        <Text mb="12px" color="#fff" fontWeight="500" fontSize="14px" lineHeight="140%">
          {t("Page not found")}
        </Text>
        <Link href="/" passHref>
          <StyledButton>{t("Back Home")}</StyledButton>
        </Link>
      </StyledNotFound>
    </>
  );
};

export default NotFound;
